@font-face {
  font-family: "Jokerman-custom";
  src: url('./fonts/Jokerman/Jokerman.woff2') format('woff2'),
    url('./fonts/Jokerman/Jokerman.woff') format('woff'),
    url("./fonts/Jokerman/Jokerman.ttf") format("truetype");
}
/* * {
  cursor: none;
} */

/* body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, FFFTusj, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
